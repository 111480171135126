import AxiosRequest from "../../utils/axios-request"

export default (uid) ->
  self = this
  AxiosRequest("/delivery/#{uid}/stores", {id: self.orde_delivery.idorder_purchase}, 'post').then ({data}) ->
    option_stores = data.stores.map((e) => ("<option value=\"#{e.idstore}\">#{e.name}</option>"))
    Swal.fire({
      showCancelButton: true
      confirmButtonText: 'Continuar'
      cancelButtonText: 'Cancelar'
      allowOutsideClick: false
      html:
        '<h3>Seleccione una tienda para el envío. </h3>'+
        "<select id='store' class='swal2-input'><option value='0'>Seleccione una opción</option>"+option_stores+"</select>"
      preConfirm: ->
        store = document.getElementById('store').value
        if parseInt(store) == 0
          Swal.showValidationMessage('Seleccione una tienda')
    }).then (request) ->
      if request.value
        store = document.getElementById('store').value
        AxiosRequest("/delivery/#{uid}/store", {id: self.orde_delivery.idorder_purchase, store: store }, 'post').then ({data}) ->
          Swal.fire({
            toast: true,
            position: 'top-end'
            icon: 'success'
            title: data.message
            showConfirmButton: false
            timer: 4000
          }).then ->
            location.reload()
