import AxiosRequest from "../../utils/axios-request"

export default (uid, approved, process, paid, cancel_by_com, cancel_by_kryon, dispatch_Collected, jwt = '', dev) ->
  if JSON.parse dev
    router_api = 'http://dev.allkryon.com:3000/'
  else
    router_api = 'https://api.kryon.digital/'
  self = this
  $.get('/delivery_states').done (data) =>
    state_find = data.state.find((e) => (e.id == self.orde_delivery.condition_id))
    kryon = data.state.find((e) => (e.id == cancel_by_kryon))
    com = data.state.find((e) => (e.id == cancel_by_com))
    state_filter = data.state.filter((e) =>
      if self.orde_delivery.condition_id == approved
        (e.id != self.orde_delivery.condition_id && e.id != process && e.id != dispatch_Collected)
      else if self.orde_delivery.condition_id == paid && com != undefined
        (e.id != self.orde_delivery.condition_id && e.id != process && e.id != approved && e.id != cancel_by_com)
      else if self.orde_delivery.condition_id == paid && kryon != undefined
        (e.id != self.orde_delivery.condition_id && e.id != process && e.id != approved && e.id != cancel_by_kryon)
      else
        (e.id != self.orde_delivery.condition_id && e.id != dispatch_Collected)
    )
    option_order = state_filter.map((e) => ("<option value=\"#{e.id}\">#{e.name}</option>"))
    Swal.fire({
      showCancelButton: true
      confirmButtonText: 'Cambiar estado'
      cancelButtonText: 'Cancelar'
      allowOutsideClick: false
      html:
        '<h3>Seleccione el nuevo estado. </h3>'+
        "<select id='state_order' class='swal2-input'><option value='#{state_find.id}'>#{state_find.name}</option>"+option_order+"</select>"
      preConfirm: ->
        state_order = document.getElementById('state_order').value
        if self.orde_delivery.condition_id == parseInt(state_order)
          Swal.showValidationMessage('Seleccione un estado diferente')
    }).then (res) =>
      if res.value
        state = document.getElementById('state_order').value
        AxiosRequest("/delivery/#{uid}/change_status", {id: self.orde_delivery.idorder_purchase, state: state}, 'post').then ({data}) ->
          Swal.fire({
            icon: 'success'
            title: data.message
            showConfirmButton: false
            timer: 2000
            timerProgressBar: true
          }).then ->
            authenticity_token = $('[name="csrf-token"]')[0].content
            final_data = {
              idshopping_cart: self.orde_delivery.shopping_cart_id,
              authenticity_token
            }
            if parseInt(state) == paid
              request = $.ajax(
                {
                  url: "#{router_api}api/v1/transactional/#{self.customer}/counter_deliveries/counter_breakdown",
                  data: JSON.stringify(final_data),
                  method: 'post',
                  headers: { 'Authorization': "Bearer #{jwt}" }
                  processData: false
                  contentType: "application/json"
                }
              )
              request.done (data) ->
                location.reload()
              request.fail (data) ->
                Swal.fire({
                  icon: 'error'
                  title: 'Error'
                  title: data.responseJSON.message
                  showConfirmButton: false
                  timer: 3000
                })
            else
              location.reload()
